import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyTranslate',
  pure: false,
  standalone: true,
})
export class CurrencyTranslatePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'ريال سعودي':
      case 'SAR':
        return 'PRODUCTS_PAGE.CURRENCY.SAUDI_RIYAL';
      case 'جنيه مصري':
      case 'EGP':
        return 'PRODUCTS_PAGE.CURRENCY.EGYPTIAN_POUND';
      case 'درهم اماراتي':
      case 'AED':
        return 'PRODUCTS_PAGE.CURRENCY.UAE_DHIRAM';
      case 'KWD':
      case 'دينار كويتي':
        return 'PRODUCTS_PAGE.CURRENCY.KUWAITI_DINAR';
      case 'OMR':
      case 'ريال عماني':
        return 'PRODUCTS_PAGE.CURRENCY.OMANI_RIAL';
      default:
        return value;
    }
  }
}
